import React from "react";
import {
  Grid,
  Container,
  makeStyles,
  Typography,
  Paper,
  Card,
  CardActions,
  IconButton,
  CardContent,
  CardActionArea,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import TwitterIcon from "@material-ui/icons/Twitter";
import EmailIcon from "@material-ui/icons/Email";
import SchoolIcon from "@material-ui/icons/School";
import FacebookIcon from "@material-ui/icons/Facebook";
import BusinessIcon from "@material-ui/icons/Business";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ReactTooltip from "react-tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  icon: {
    color: "white",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  profile: {
    fontSize: "3rem",
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

const publications = [
  {
    name: "Data-Driven Mobility Models for COVID-19 Simulation",
    text: (
      <>
        J. Pesavento, A. Chen, R. Yu, J.-S. Kim, H. Kavak, T. Anderson, and A.
        Züfle, “Data Driven Mobility Models for COVID-19 Simulation,”{" "}
        <i>
          In Proceedings of the 3rd ACM SIGSPATIAL Workshop on Advances in
          Resilient and Intelligent Cities (ARIC 2020)
        </i>
        , November 2020, pp. 29-38
      </>
    ),
    abstract: (
      <>
        Agent-based models (ABM) play a prominent role in guiding critical
        decision-making and supporting the development of effective policies for
        better urban resilience and response to the COVID-19 pandemic. However,
        many ABMs lack realistic representations of human mobility, a key
        process that leads to physical interaction and subsequent spread of
        disease. Therefore, we propose the application of Latent Dirichlet
        Allocation (LDA), a topic modeling technique, to foot-traffic data to
        develop a realistic model of human mobility in an ABM that simulates the
        spread of COVID-19. In our novel approach, LDA treats POIs as “words”
        and agent home census block groups (CBGs) as “documents” to extract
        “topics” of POIs that frequently appear together in CBG visits. These
        topics allow us to simulate agent mobility based on the LDA topic
        distribution of their home CBG. We compare the LDA based mobility model
        with competitor approaches including a naive mobility model that assumes
        visits to POIs are random. We find that the naive mobility model is
        unable to facilitate the spread of COVID-19 at all. Using the LDA
        informed mobility model, we simulate the spread of COVID-19 and test the
        effect of changes to the number of topics, various parameters, and
        public health interventions. By examining the simulated number of cases
        over time, we find that the number of topics does indeed impact disease
        spread dynamics, but only in terms of the outbreak's timing. Further
        analysis of simulation results is needed to better understand the impact
        of topics on simulated COVID-19 spread. This study contributes to
        strengthening human mobility representations in ABMs of disease spread.
      </>
    ),
    scholar: "https://dl.acm.org/doi/abs/10.1145/3423455.3430305",
  },
  {
    name: "COVID-19 Ensemble Models Using Representative Clustering",
    text: (
      <>
        J-S. Kim, H. Kavak, A. Züfle, T. Anderson, “COVID-19 Ensemble Models
        Using Representative Clustering,” <i>SIGSPATIAL Special</i>, July 2020,
        Volume 12, Issue 2, pp 33-41
      </>
    ),
    abstract: (
      <>
        In response to the COVID-19 pandemic, there have been various attempts
        to develop realistic models to both predict the spread of the disease
        and evaluate policy measures aimed at mitigation. Different models that
        operate under different parameters and assumptions produce radically
        different predictions, creating confusion among policy-makers and the
        general population and limiting the usefulness of the models. This
        newsletter article proposes a novel ensemble modeling approach that uses
        representative clustering to identify where existing model predictions
        of COVID-19 spread agree and unify these predictions into a smaller set
        of predictions. The proposed ensemble prediction approach is composed of
        the following stages: (1) the selection of the ensemble components, (2)
        the imputation of missing predictions for each component, and (3)
        representative clustering in application to time-series data to
        determine the degree of agreement between simulation predictions. The
        results of the proposed approach will produce a set of ensemble model
        predictions that identify where simulation results converge so that
        policy-makers and the general public are informed with more
        comprehensive predictions and the uncertainty among them.
      </>
    ),
    scholar: "https://doi.org/10.1145/3431843.3431848",
  },
  {
    name: "Expert-in-the-Loop Prescriptive Analytics using Mobility Intervention for Epidemics",
    text: (
      <>
        J.-S. Kim, H. Jin, and A. Züfle, “Expert-in-the-Loop Prescriptive
        Analytics using Mobility Intervention for Epidemics,” 1st ACM SIGKDD
        International Workshop on Prescriptive Analytics for the Physical World
        (PAPW 2020), August 2020
      </>
    ),
    abstract: (
      <>
        Due to complexity of social phenomena, it is a big challenge to predict
        the curves of epidemics that spread via social contacts and to control
        such epidemics. Misguided policies to mitigate epidemics may result in
        catastrophic consequences such as financial crisis, massive
        unemployment, and the surge of the number of critically ill patients
        exceeding the capacity of hospitals. In particular, under/overestimation
        of efficacy of interventions can mislead policymakers about perception
        of evolving situations. To avoid such pitfalls, we propose
        Expert-in-the-Loop (EITL) prescriptive analytics using mobility
        intervention for epidemics. Rather than employing a purely data-driven
        approach, the key advantage of our approach is to leverage experts' best
        knowledge in estimating disease spreading and the efficacy of
        interventions which allows us to efficiently narrow down factors and the
        scope of combinatorial possible worlds. We introduce our experience to
        develop Expert-in-the-Loop simulations during the Challenge on Mobility
        Intervention for Epidemics. We demonstrate that misconceptions about the
        causality can be corrected in the iterations of consulting with experts,
        developing simulations, and experimentation.
      </>
    ),
    scholar:
      "https://www.researchgate.net/publication/343906417_Expert-in-the-Loop_Prescriptive_Analytics_using_Mobility_Intervention_for_Epidemics",
  },
];

export default () => {
  const handleEmailClick = (e, email) => {
    if (document.queryCommandSupported("copy")) {
      const textField = document.createElement("textarea");
      textField.innerText = email;
      const parentElement = document.getElementById("placeholder");
      parentElement.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      parentElement.removeChild(textField);
    }
  };

  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h4" gutterBottom>
              Publications
            </Typography>
            <Typography variant="body1" gutterBottom paragraph align="left">
              {publications.map((d, i) => {
                return <li key={i}>{d.text}</li>;
              })}
            </Typography>
          </Paper>
        </Grid>
        {publications.map((d, i) => {
          return (
            <Grid key={i} item xs={12} sm={6}>
              <Card>
                <CardActionArea>
                  <CardContent>
                    {d.logo &&
                      d.logo.map((v, index) => {
                        return (
                          <img key={index} alt={d.name} src={v} width={100} />
                        );
                      })}
                    <Typography variant="subtitle1" gutterBottom>
                      {d.name}
                    </Typography>
                    <Typography variant="body2" gutterBottom align="justify">
                      {d.abstract}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions disableSpacing>
                  {d.dept !== undefined && (
                    <IconButton aria-label="Office" href={d.dept}>
                      <BusinessIcon />
                    </IconButton>
                  )}
                  {d.homepage !== undefined && (
                    <IconButton aria-label="Homepage" href={d.homepage}>
                      <HomeIcon />
                    </IconButton>
                  )}
                  {d.scholar !== undefined && (
                    <IconButton
                      aria-label="Google Scholar"
                      href={d.scholar}
                      target="_blank"
                    >
                      <SchoolIcon />
                    </IconButton>
                  )}
                  {d.twitter !== undefined && (
                    <IconButton aria-label="email" href={d.twitter}>
                      <TwitterIcon />
                    </IconButton>
                  )}
                  {d.email !== undefined && (
                    <IconButton
                      aria-label="email"
                      data-for="custom-event"
                      data-event="click"
                      data-event-off="mouseleave"
                      data-tip="Copied!"
                      onMouseDown={(e) => handleEmailClick(e, d.email)}
                    >
                      <EmailIcon />
                    </IconButton>
                  )}
                  {d.facebook !== undefined && (
                    <IconButton aria-label="facebook" href={d.facebook}>
                      <FacebookIcon />
                    </IconButton>
                  )}
                </CardActions>
              </Card>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h4" gutterBottom>
              Outreach
            </Typography>
            <Typography variant="body1" gutterBottom paragraph align="left">
              Outreach comes here.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <div id="placeholder"></div>
      <ReactTooltip id="custom-event" globalEventOff="click" />
    </Container>
  );
};
