import React from "react";
import * as d3 from "d3";
import { format } from "d3-format";
import { geoPath } from "d3-geo";
import "./App.css";
import { ZoomContainer } from "./zoom-container";
import { Stage } from "./stage";
import ReactTooltip from "react-tooltip";
import { LegendLinear, LegendItem, LegendLabel } from "@vx/legend";
import { scaleLinear } from "@vx/scale";

import { LocationToName } from "./LocationName";

const oneDecimalFormat = format("d");

const topojson = require("topojson-client");
// const us = require("./counties-albers-10m.json");
const us = require("./states-albers-10m.json");

function USMap(props: any) {
  // if(props.data.length === 1)
  // return (
  //   <div width="100%" height="100">
  //     No data available. Select other options.
  //   </div>
  // )

  // const projection = d3.geoAlbersUsa().scale(1300).translate([487.5, 305])
  const path = geoPath();
  const max: any = props.data
    ? d3.max(props.data, (d: any) => (d.location === "US" ? 0 : d.value))
    : 1000;
  const theme = {
    domain: [0, max * 0.25, max * 0.75, max],
    range: ["white", "orange", "red", "black"],
  };
  const threshold = scaleLinear(theme);
  const linearScale = d3
    .scaleLinear<string>()
    .domain(theme.domain)
    .range(theme.range);
  // const counties = (<path stroke="#aaa" stroke-width="0.5" d={path(topojson.mesh(us, us.objects.counties, (a, b) => a !== b && (a.id / 1000 | 0) === (b.id / 1000 | 0)))}></path>)
  // //const states = (<path stroke-width="0.5" d={path(topojson.mesh(us, us.objects.states, (a, b) => a !== b))}></path>)
  const percent = props.quantile !== undefined ? props.quantile : 0;

  // console.log(props.date, props.forecastDate, props.data, percent)
  const states = topojson
    .feature(us, us.objects.states)
    .features.map((feature: any, i: number) => {
      const stateHovered =
        props.data &&
        props.data.find(
          (element: any) =>
            element.location === feature.id && //properties.name &&
            Math.abs(parseFloat(element.quantile) - percent) <= 0.001 &&
            element.target_end_date === props.date &&
            element.forecast_date === props.forecastDate
        );
      return (
        <path
          key={feature.id}
          d={path(feature)!}
          fill={linearScale(stateHovered && stateHovered.value)}
          stroke={stateHovered && props.selectedElement === stateHovered ? "#888" : "#ccc"}
          strokeWidth={stateHovered && props.selectedElement === stateHovered ? 5 : 1}
          // fillOpacity={props.hoverElement === stateHovered ? 0.5 : 1}
          // onMouseEnter={() => {props.onHover(stateHovered)}}
          // onMouseLeave={() => {props.onHover(null)}}
          onClick={(e) => {
            e.stopPropagation();
            props.onClick(stateHovered);
          }}
          data-tip={
            stateHovered
              ? `<table>
        <tbody>
          <tr>
              <td>Location</td>
              <td>${
                LocationToName[stateHovered.location] ||
                stateHovered.location_name ||
                stateHovered.location
              }</td>
          </tr>
          <tr>
              <td>Forecast</td>
              <td>${stateHovered.forecast_date}</td>
          </tr>
          <tr>
              <td>Prediction Date</td>
              <td>${stateHovered.target_end_date}</td>
          </tr>
          <tr>
              <td>Target</td>
              <td>${stateHovered.target}</td>
          </tr>
          <tr>
              <td>Quantile</td>
              <td>${stateHovered.quantile}</td>
          </tr>
          <tr>
              <td>Prediction</td>
              <td>${Math.floor(stateHovered.value)}</td>
          </tr>
        </tbody>
      </table>`
              : "none"
          }
          data-for="mapTooltip"
          data-html={true}
        ></path>
      );
    });

  const nation = topojson.feature(us, us.objects.nation);
  const usData =
    props.data &&
    props.data.find(
      (element: any) =>
        element.location === "US" &&
        Math.abs(parseFloat(element.quantile) - percent) <= 0.001 &&
        element.target_end_date === props.date &&
        element.forecast_date === props.forecastDate
    );
  const nationJSX = <path d={path(nation)!}></path>;
  // const bbox = path.bounds(nation);
  const legendGlyphSize = 15;
  const events = false;
  return (
    <div
      onClick={() => {
        props.onClick({ location: "US" });
      }}
      data-tip={
        usData
          ? `<table>
<tbody>
  <tr>
      <td>Location</td>
      <td>${"US"}</td>
  </tr>
  <tr>
      <td>Forecast</td>
      <td>${usData.forecast_date}</td>
  </tr>
  <tr>
      <td>Prediction Date</td>
      <td>${usData.target_end_date}</td>
  </tr>
  <tr>
      <td>Target</td>
      <td>${usData.target}</td>
  </tr>
  <tr>
      <td>Quantile</td>
      <td>${usData.quantile}</td>
  </tr>
  <tr>
      <td>Prediction</td>
      <td>${Math.floor(usData.value)}</td>
  </tr>
</tbody>
</table>`
          : ""
      }
      data-for="usmapTooltip"
      data-html={true}
    >
      {props.data && props.data.length > 1 && (
        <LegendWrapper title={`${props.targetType} toll`}>
          <LegendLinear
            steps={9}
            scale={threshold}
            labelFormat={(d, i) => (i % 2 === 0 ? oneDecimalFormat(d) : "")}
          >
            {(labels) =>
              labels.map((label, i) => (
                <LegendItem
                  key={`legend-quantile-${i}`}
                  onClick={() => {
                    if (events) alert(`clicked: ${JSON.stringify(label)}`);
                  }}
                >
                  <svg
                    width={legendGlyphSize}
                    height={legendGlyphSize}
                    style={{ margin: "0 0" }}
                  >
                    <rect
                      fill={label.value}
                      width={legendGlyphSize}
                      height={legendGlyphSize}
                    />
                    {/* <circle
                    fill={label.value}
                    r={legendGlyphSize / 2}
                    cx={legendGlyphSize / 2}
                    cy={legendGlyphSize / 2}
                  /> */}
                  </svg>
                  <LegendLabel align="left" margin="0 4px">
                    {label.text}
                  </LegendLabel>
                </LegendItem>
              ))
            }
          </LegendLinear>
        </LegendWrapper>
      )}
      <ReactTooltip html={true} id="usmapTooltip" />
      <ReactTooltip html={true} id="mapTooltip" />
      <Stage minx="0" miny="0" width="100%" height="500">
        <ZoomContainer>
          <g fill="none" stroke="#ccc">
            {/*counties*/}
            {states}
            {nationJSX}
          </g>
        </ZoomContainer>
      </Stage>
    </div>
  );
}

function LegendWrapper({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <div className="legend">
      <div className="title">{title}</div>
      {children}
      <style>{`
        .legend {
          position: absolute;
          line-height: 0.9em;
          color: #000;
          background: #bbb;
          font-size: 11px;
          font-family: arial;
          padding: 10px 10px;
          float: left;
          border: 1px solid rgba(0, 0, 0, 1);
          border-radius: 8px;
          margin: 5px 5px;
        }
        .title {
          font-size: 12px;
          margin-bottom: 10px;
          font-weight: 100;
        }
      `}</style>
    </div>
  );
}

export default USMap;
