import React from "react";
import {
  Grid,
  Container,
  makeStyles,
  Typography,
  Paper,
  Card,
  CardActions,
  IconButton,
  CardContent,
  CardActionArea,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import TwitterIcon from "@material-ui/icons/Twitter";
import EmailIcon from "@material-ui/icons/Email";
import SchoolIcon from "@material-ui/icons/School";
import FacebookIcon from "@material-ui/icons/Facebook";
import BusinessIcon from "@material-ui/icons/Business";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ReactTooltip from "react-tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  icon: {
    color: "white",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  profile: {
    fontSize: "3rem",
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

const models = [
  {
    name: "Auquan Data Science",
    logo: [
      "https://covid19-infection-model.auquan.com:443/media/f44679850932ba9287f3185c5dccb0a4d19d4c9f6c8c6981fb981213.jpeg",
    ],
    text: `A modified [Susceptible Exposed Infected Reported] (SEIR) model
      with compartments for reported and unreported infections. 
      Non-linear mixed effects curve-fitting. (From Reich metadata)`,
    homepage: "https://covid19-infection-model.auquan.com",
  },
  {
    name: "COVID-19 Simulator Consortium",
    logo: ["https://covid19sim.org/images/COVID19_darkbg-65px.png"],
    text: `A validated compartment model to simulate the trajectory of 
      COVID-19 at the state level. (From Reich metadata)`,
    homepage: "https://covid19sim.org/",
  },
  {
    name: "Columbia University",
    text: `A metapopulation [Susceptible Exposed Infected Reported] SEIR model
      to simulate the spread of COVID-19 at the county level (Pei & Shaman, 2020).`,
    homepage: "https://covidprojections.azurewebsites.net/",
  },
  {
    name: "CovidActNow ",
    logo: ["https://covidactnow.org/static/media/can_logo.0ac0983b.png"],
    text: `A [Susceptible Exposed Infected Reported] (SEIR) model 
      that makes forecasts of cumulative deaths, incident deaths, 
      incident hospitalizations by fitting predicted cases, deaths, 
      and hospitalizations to observations. (From Reich metadata)`,
    homepage: "https://covidactnow.org/?s=39636",
  },
  {
    name: "Georgia Institute of Technology ",
    logo: [
      "https://www.cc.gatech.edu/~badityap/covid-dir/aditya-lab-logo.png",
      "https://www.cc.gatech.edu/~badityap/covid-dir/gt-logo-black-gold.png",
    ],
    text: `Deep learning models to forecast specific [COVID-19 related] targets
      at the national, regional, state, and local levels.`,
    homepage: "https://www.cc.gatech.edu/~badityap/covid.html",
  },
  {
    name: "Georgia Institute of Technology ",
    logo: [
      "https://www.cc.gatech.edu/~badityap/covid-dir/gt-logo-black-gold.png",
    ],
    text: `An agent-based simulation model to project the infection spread.`,
    homepage: "https://github.com/pkeskinocak/COVID19GA",
  },
  {
    name: "University of Geneva / Swiss Data Center",
    text: `The growth rate of cumulative cases (resp. deaths) between two days
      ago and today [is calculated]. If it's greater than 5%, an exponential
      model [is used] to forecast the cumulative number of cases (resp. deaths),
      and then derive the daily number of cases (resp. deaths). If it's less 
      than 5%, a linear model [is used] instead.`,
    homepage: "https://renkulab.shinyapps.io/COVID-19-Epidemic-Forecasting/",
  },
  {
    name: "Institute for Health Metrics and Evaluation (IHME)",
    logo: [
        "https://covid19.healthdata.org/dist/images/ihme-logo.svg",
      ],
    text: `IHME uses a hybrid modeling approach to generate our forecasts,
      which incorporates elements of statistical and disease transmission models.
      This approach can be described as non-linear mixed effects curve-fitting.
      (From Reich metadata)`,
    homepage: "https://covid19.healthdata.org/united-states-of-america",
  },
  {
    name: "Iowa State - Lily Wang’s Research Group",
    text: `Established a new spatiotemporal epidemic modeling (STEM) framework
      for space-time infected/death count data to study the dynamic pattern
      in the spread of COVID-19.`,
    homepage: "https://covid19.stat.iastate.edu/",
  },
  {
    name: "Johns Hopkins ID Dynamics COVID-19 Working Group",
    text: `County-level metapopulation model with commuting and stochastic 
      [Susceptible Exposed Infected Reported] SEIR disease dynamics.
      (From Reich metadata)`,
    homepage: "https://github.com/HopkinsIDD/COVIDScenarioPipeline",
  },
  {
    name: "Los Alamos National Labs",
    logo: [
        "https://covid-19.bsvgateway.org/static/images/los-alamos-lab-logo-white.png",
      ],
    text: `The model consists of two processes. The first process is 
      a statistical model of how the number of COVID-19 infections 
      changes over time. The second process maps the number of 
      infections to the reported data.`,
    homepage: "https://covid-19.bsvgateway.org/",
  },
  {
    name: "CovidAnalytics at MIT",
    logo: [
        "https://www.covidanalytics.io/assets/images/logo_black.png",
      ],
    text: `A standard [Susceptible Exposed Infected Reported] SEIR model 
      with additional features specific to the COVID-19 pandemic, 
      like under-detection and differentiated government intervention.`,
    homepage: "https://www.covidanalytics.io/projections",
  },
  {
    name: "MOBS Lab at Northeastern",
    logo: [
        "https://covid19.gleamproject.org/covid-19-logo-us.png",
      ],
    text: `The Global Epidemic and Mobility Model (GLEAM), 
     an individual-based, stochastic, and spatial epidemic model.`,
    homepage: "https://covid19.gleamproject.org/",
  },
  {
    name: "NotreDame-FRED",
    text: `An existing agent-based model, FRED (Framework for 
      Reconstructing Epidemic Dynamics), which was originally 
      developed by the University of Pittsburgh in response to 
      the 2009 influenza pandemic.`,
    homepage: "https://github.com/confunguido/covid19_ND_forecasting",
  },
  {
    name: "Predictive Science Inc",
    text: `A stochastic/deterministic, single-population 
      SEIRX model that stratifies by both age distribution and
      disease severity and includes generic intervention fitting.
      (From Reich metadata)`,
    homepage: "https://q.predsci.com/covid19/",
  },
  {
    name: "Quantori Modeling",
    text: `An “agent-based local stochastic model of COVID-19 
      transmission” (From Reich metadata).`,
  },
  {
    name: "Snyder Wilson Consulting",
    text: `Terminus is a mechanistic Compartmental Model using 
      disease parameter estimates from literature. It uses Bayesian 
      inference to predict the most likely model parameters. 
      (From Reich metadata)`,
  },
  {
    name: "University of Arizona",
    text: `The model is an extension of the EpiGro (Lega & Brown, 2016).
      To combine Covid-19 data with the model during the pandemic,
      a variational data assimilation method is used (sometimes 
      called  4D-Var) to fit model parameters.(From Reich metadata)`,
    homepage: "https://covidtracking.com",
  },
  {
    name: "UCLA Statistical Machine Learning Lab",
    logo: [
        "https://covid19.uclaml.org/figures/ucla.png",
      ],
    text: `An improved [Susceptible Exposed Infected Reported] SEIR model
      for predicting the dynamics among the cumulative confirmed cases 
      and death of COVID-19.`,
    homepage: "https://covid19.uclaml.org/model.html",
  },
  {
    name: "University of Chicago",
    text: `A compartmental [Susceptible Exposed Infected Reported] SEIR model.`,
    homepage: "https://github.com/cobeylab/covid_IL",
  },
  {
    name: "UMass-Amherst Influenza Forecasting Center of Excellence & College of Information and Computer Sciences",
    text: `A Bayesian estimation of compartmental models for COVID-19.`,
    homepage: "https://github.com/dsheldon/covid",
  },
  {
    name: "US Army Engineer Research and Development Center",
    text: `The ERDC [Susceptible Exposed Infected Reported] SEIR model
      makes predictions of several variables (e.g., reported new cases 
      per day, cumulative reported cases, the susceptible population, etc...).
      (From Reich metadata)`,
    homepage: "https://github.com/reichlab/covid19-forecast-hub/blob/master/data-processed/USACE-ERDC_SEIR/metadata-USACE-ERDC_SEIR.txt",
  },
  {
    name: "UT-Austin COVID-19 Consortium",
    logo: [
        "https://covid-19.tacc.utexas.edu/static/img/Covid-Logo-Final-300px.png",
      ],
    text: `[Mobility data] is aggregated for each state to create metrics 
      of social distancing, which are used as predictors in the model... 
      a Bayesian multilevel negative binomial regression model [is used] 
      for the number of deaths for each day. (From Reich metadata)`,
    homepage: "https://covid-19.tacc.utexas.edu/projections/",
  },
  {
    name: "YYG",
    text: `We present an intuitive model that builds machine learning
      techniques on top of a classic infectious disease model to 
      make COVID-19 infections and deaths projections for the US, 
      all 50 US states, and more than 70 countries.`,
    homepage: "https://covid19-projections.com/",
  },
  {
    name: "COVID Forecast Hub",
    text: `Ensemble Model`,
  },
  {
    name: "UMass-Amhert Influenza Forecasting Center of Excellence",
    text: `Ensemble Model`,
  },
  {
    name: "Imperial x 2",
    text: `Ensemble Model`,
  },
  {
    name: "ISU and PKU",
    text: `Ensemble Model`,
  },
];

export default () => {
  const handleEmailClick = (e, email) => {
    if (document.queryCommandSupported("copy")) {
      const textField = document.createElement("textarea");
      textField.innerText = email;
      const parentElement = document.getElementById("placeholder");
      parentElement.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      parentElement.removeChild(textField);
    }
  };

  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h4" gutterBottom>
              Models
            </Typography>
            <Typography variant="body1" gutterBottom paragraph align="left">
              Models are listed alphabetically. This page is updated on August 7, 2020. 
              If you want your model to be added to
              our dashboard, please complete a request and send us by clicking
              the following button.
            </Typography>
            <IconButton
              aria-label="email"
              href={"https://forms.gle/cqGJoYrVtQJUiMSq5"} target="_blank"
            >
              <AddCircleIcon fontSize={'large'}/>
            </IconButton>
          </Paper>
        </Grid>
        {models.map((d, i) => {
          return (
            <Grid key={i} item xs={12} sm={6}>
              <Card>
                <CardActionArea>
                  <CardContent>
                    <center>
                      {d.logo &&
                        d.logo.map((v, index) => {
                          return <img key={index} alt={d.name} src={v} width={100} />;
                        })}
                    </center>
                    <Typography variant="subtitle1" gutterBottom>
                      {d.name}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {d.text}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions disableSpacing>
                  {d.dept !== undefined && (
                    <IconButton aria-label="Office" href={d.dept}>
                      <BusinessIcon />
                    </IconButton>
                  )}
                  {d.homepage !== undefined && (
                    <IconButton aria-label="Homepage" href={d.homepage}>
                      <HomeIcon />
                    </IconButton>
                  )}
                  {d.scholar !== undefined && (
                    <IconButton aria-label="Google Scholar" href={d.scholar}>
                      <SchoolIcon />
                    </IconButton>
                  )}
                  {d.twitter !== undefined && (
                    <IconButton aria-label="email" href={d.twitter}>
                      <TwitterIcon />
                    </IconButton>
                  )}
                  {d.email !== undefined && (
                    <IconButton
                      aria-label="email"
                      data-for="custom-event"
                      data-event="click"
                      data-event-off="mouseleave"
                      data-tip="Copied!"
                      onMouseDown={(e) => handleEmailClick(e, d.email)}
                    >
                      <EmailIcon />
                    </IconButton>
                  )}
                  {d.facebook !== undefined && (
                    <IconButton aria-label="facebook" href={d.facebook}>
                      <FacebookIcon />
                    </IconButton>
                  )}
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <div id="placeholder"></div>
      <ReactTooltip id="custom-event" globalEventOff="click" />
    </Container>
  );
};
