import React from "react";
// import useChartConfig from "./hooks/useChartConfig";
import { Chart } from "react-charts";
import { IconButton } from "@material-ui/core";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
// import { LocationToName } from "./LocationName";

const findColumn = (from, x) => {
  var a = from.find((el) => el.x === x);
  return a.y;
};

const colorPalette = [
  "#006d92",
  "#cd82ad",
  "#de7c00",
  "#f33232",
  "#3f9a80",
  "#53c200",
  "#d7af00",
  "#4c26c9",
  "#d44d99",
  "#0f83ab",
  "#faa43a",
  "#ff4e4e",
  "#53cfc9",
  "#a2d925",
  "#decf3f",
  "#734fe9",
];

export default ({
  elementType,
  activeDatumIndex,
  activeSeriesIndex,
  targetDate,
  asDate,
  setState,
  originalData,
  upper,
  lower,
  center,
  gt,
  location,
  inc = false
}) => {
  // const { data } = useChartConfig({
  //   series: 10,
  //   height: 100,
  //   // grouping: 'primary',
  //   // dataType: 'ordinal',
  //   // show: ['elementType']
  // })
  const data = React.useMemo(
    (data) => {
      const tmp = originalData
        .filter(
          (v) =>
            location !== null &&
            v.location === location.id &&
            upper !== null &&
            Math.abs(v.quantile - upper.value) < 0.001
        )
        .map((v, i) => {
          return {
            x: v.target_end_date,
            y: v.value,
            base: v.value,
            quantile: v.quantile,
            label: v.quantile * 100 + " Quantile",
          };
        })
        .reduce((rv, x) => {
          (rv[x.quantile] = rv[x.quantile] || []).push(x);
          return rv;
        }, {});
      const upperCurve = Object.entries(tmp).map((v, i) => {
        return { label: v[1][0].label, data: v[1] };
      });
      const tmp2 = originalData
        .filter(
          (v) =>
            location !== null &&
            v.location === location.id &&
            lower !== null &&
            Math.abs(v.quantile - lower.value) < 0.001
        )
        .map((v, i) => {
          return {
            x: v.target_end_date,
            y: v.value,
            base: v.value,
            quantile: v.quantile,
            label: v.quantile * 100 + " Quantile",
          };
        })
        .reduce((rv, x) => {
          (rv[x.quantile] = rv[x.quantile] || []).push(x);
          return rv;
        }, {});
      const lowerCurve = Object.entries(tmp2).map((v, i) => {
        return { label: v[1][0].label, data: v[1] };
      });
      const tmp1 = originalData
        .filter(
          (v) =>
            location !== null &&
            v.location === location.id &&
            center !== null &&
            Math.abs(v.quantile - center.value) < 0.001
        )
        .map((v, i) => {
          return {
            x: new Date(v.target_end_date),
            y: v.value,
            base:
              lowerCurve[0] ?
              findColumn(lowerCurve[0].data, v.target_end_date) : v.value,
            upper:
              upperCurve[0] ?
              findColumn(upperCurve[0].data, v.target_end_date) : v.value,
            quantile: v.quantile,
            label: v.quantile * 100 + " Quantile",
          };
        })
        .reduce((rv, x) => {
          (rv[x.quantile] = rv[x.quantile] || []).push(x);
          return rv;
        }, {});
      const centerCurve = Object.entries(tmp1).map((v, i) => {
        return { label: v[1][0].label, data: v[1] };
      });

      const tmp3 = gt
        .filter((v) => location !== null && v.unit === location.id)
        .map((v, i) => {
          return {
            x: new Date(v.timezero),
            y: v.value,
            base: v.value,
            upper: v.value,
            label: "Ground truth",
          };
        })
        .reduce((rv, x) => {
          (rv[x.label] = rv[x.label] || []).push(x);
          return rv;
        }, {});
      const truthCurve = Object.entries(tmp3).map((v, i) => {
        return { label: v[1][0].label, data: v[1] };
      });
      //   return lowerCurve.concat(centerCurve).concat(upperCurve)
      return centerCurve.concat(truthCurve);
    },
    [originalData, upper, lower, center, gt, location, inc]
  );

  // console.log(data, quantile);
  // Use data.lines[n].data to represent the different datums for each series
  // const getDatums = React.useCallback((series) => {
  //   console.log(series);
  //   return series.data;
  // }, []);

  // Use the original data object and the datum index to reference the datum's primary value.
  // const getPrimary = React.useCallback(
  //   (datum, i, series, seriesIndex, data) => datum[i], //.find()axis[i],
  //   []
  // );

  // Use data.lines[n].data[n].value as each datums secondary value
  // const getSecondary = React.useCallback((datum) => datum.value, []);
  const [{ min, max }, setMinMaxState] = React.useState({
    min: null,
    max: null,
  });

  const series = React.useMemo(
    () => ({
      type: elementType,
    }),
    [elementType]
  );
  const axes = React.useMemo(
    () => [
      {
        primary: true,
        type: "utc",
        position: "bottom",
        hardMin: min,
        hardMax: max,
      },
      {
        type: "linear",
        position: "left",
      },
    ],
    [max, min]
  );
  const getSeriesStyle = React.useCallback(
    (series) => {
      return {
        // color: `url(#${series.index % 4})`,
        transition: "all .5s ease",
        color: colorPalette[series.index],
        opacity:
          activeSeriesIndex > -1
            ? series.index === activeSeriesIndex
              ? 0.5
              : 0.3
            : 0.3,
      };
    },
    [activeSeriesIndex]
  );
  const getDatumStyle = React.useCallback(
    (datum) => ({
      r:
        activeDatumIndex === datum.index &&
        activeSeriesIndex === datum.seriesIndex
          ? 7
          : activeDatumIndex === datum.index
          ? 5
          : datum.series.index === activeSeriesIndex
          ? 3
          : datum.otherHovered
          ? 2
          : 2,
    }),
    [activeDatumIndex, activeSeriesIndex]
  );
  const onFocus = React.useCallback(
    (focused) => {
      // console.log("focused", focused);
      setState({
        activeSeriesIndex: focused ? focused.series.id : -1,
        activeDatumIndex: focused ? focused.index : -1,
        targetDate: targetDate,
      });
    },
    [setState, targetDate]
  );

  const handleClick = React.useCallback(
    (clicked) => {
      // console.log("click", clicked.primary)
      if (clicked?.primary !== undefined)
        setState({
          targetDate: clicked.primary,
        });
    },
    [setState]
  );

  const primaryCursor = React.useMemo(
    () => ({
      value: asDate,
    }),
    [asDate]
  );

  const brush = React.useMemo(
    () => ({
      onSelect: (brushData) => {
        if (brushData.start.getTime() !== brushData.end.getTime())
          setMinMaxState({
            min:
              brushData.start < brushData.end ? brushData.start : brushData.end,
            max:
              brushData.start > brushData.end ? brushData.start : brushData.end,
          });
      },
    }),
    []
  );

  return (
    <>
      {data?.length > 0 && (
        <>
          <div style={{ textAlign: "right" }}>
            <IconButton
              style={{ position: "absolute", zIndex: 1 }}
              onClick={() =>
                setMinMaxState({
                  min: null,
                  max: null,
                })
              }
              className="tooltip"
            >
              <span className="tooltiptext">Zoom out</span>
              <ZoomOutMapIcon fontSize={"large"} />
            </IconButton>
            <style>
              {`
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
  font-size: 1rem;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}`}
            </style>
          </div>
          <div style={{ height: 500 }}>
            <Chart
              data={data}
              // getSeries={getSeries}
              // getDatums={getDatums}
              // getPrimary={getPrimary}
              // getSecondary={getSecondary}
              // grouping={grouping}
              series={series}
              axes={axes}
              brush={brush}
              getSeriesStyle={getSeriesStyle}
              getDatumStyle={getDatumStyle}
              onFocus={onFocus}
              onClick={handleClick}
              tooltip
              primaryCursor={primaryCursor}
            />
          </div>
        </>
      )}
    </>
  );
};
