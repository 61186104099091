import React from "react";
import {
  Grid,
  Avatar,
  Container,
  makeStyles,
  Typography,
  Paper,
  Card,
  CardActions,
  IconButton,
  CardContent,
  CardActionArea,
  Link,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import TwitterIcon from "@material-ui/icons/Twitter";
import EmailIcon from "@material-ui/icons/Email";
import SchoolIcon from "@material-ui/icons/School";
import FacebookIcon from "@material-ui/icons/Facebook";
import BusinessIcon from "@material-ui/icons/Business";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import ReactTooltip from "react-tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  icon: {
    color: "white",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  profile: {
    fontSize: "3rem",
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

const members = [
  {
    name: "Taylor Anderson",
    initial: "T. A.",
    title: "Ph.D.",
    avatar: "headshot_anderson.jpg",
    association: `Assistant Professor,
    Department of Geography and Geoinformation Science,
    George Mason University`,
    dept: "https://science.gmu.edu/directory/taylor-anderson",
    homepage: "https://tayloranderson.space",
    twitter: "https://twitter.com/hellotaylora",
    email: "tander6@gmu.edu",
  },
  {
    name: "Andreas Züfle",
    initial: "A. Z.",
    title: "Ph.D.",
    avatar: "headshot_zufle.jpg",
    association: `Assistant Professor,
    Department of Geography and Geoinformation Science,
    George Mason University`,
    dept: "https://cos.gmu.edu/ggs/andreas-zufle",
    email: "azufle@gmu.edu",
  },
  {
    name: "Hamdi Kavak",
    initial: "H. K.",
    title: "Ph.D.",
    avatar: "headshot_kavak.jpg",
    association: `Assistant Professor,
    Department of Computational and Data Sciences,
    George Mason University`,
    dept: "https://science.gmu.edu/directory/hamdi-kavak",
    homepage: "https://hamdikavak.com",
    twitter: "https://twitter.com/hmdkvk",
    email: "hkavak@gmu.edu",
  },
  {
    name: "Joon-Seok Kim",
    initial: "J. K.",
    title: "Ph.D.",
    avatar: "headshot_kim.jpg",
    association: `Postdoctoral Researcher,
    Department of Geography and Geoinformation Science,
    George Mason University`,
    dept: "https://science.gmu.edu/directory/joon-seok-kim",
    homepage: "https://joonseok.org",
    email: "jkim258@gmu.edu",
  },
  {
    name: "John Pesavento",
    superscript: "*",
    initial: "J. P.",
    avatar: "headshot_pesavento.jpg",
    association: `2020 Aspiring Scientists' Summer Internship Program (ASSIP) Student,  
    Thomas Jefferson High School for Science and Technology`,
    homepage: "https://jpes707.github.io/",
    twitter: "https://twitter.com/jpes707",
    email: "2021jpesaven@tjhsst.edu",
  },
  {
    name: "Rayan Yu",
    superscript: "*",
    initial: "R. Y.",
    avatar: "headshot_yu.jpg",
    association: `2020 Aspiring Scientists' Summer Internship Program (ASSIP) Student, 
    James Madison High School`,
    email: "rayan.yu430@gmail.com",
    linkedin: "https://linkedin.com/in/rayan-yu",
  },
  {
    name: "Andy Chen",
    superscript: "*",
    avatar: "placeholder.png",
    initial: "A. C.",
    association: `2020 Aspiring Scientists' Summer Internship Program (ASSIP) Student, 
    Thomas Jefferson High School for Science and Technology`,
    email: "2021achen1@tjhsst.edu",
  },
  {
    name: "Justin Elarde",
    initial: "J. E.",
    avatar: "headshot_elarde.jpg",
    association: `Undergraduate,
    Department of Geography and Geoinformation Science,
    George Mason University`,
    email: "jelarde@masonlive.gmu.edu",
  },
  {
    name: "Cecelia O'Neill",
    initial: "C. O.",
    avatar: "headshot_oneill.jpg",
    association: `Undergraduate,
    Department of Geography and Geoinformation Science,
    George Mason University`,
    email: "coneil7@masonlive.gmu.edu",
  },
];

export default () => {
  const handleEmailClick = (e, email) => {
    if (document.queryCommandSupported("copy")) {
      const textField = document.createElement("textarea");
      textField.innerText = email;
      const parentElement = document.getElementById("placeholder");
      parentElement.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      parentElement.removeChild(textField);
    }
  };

  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h4" gutterBottom>
              Team
            </Typography>
            <Typography variant="body1" gutterBottom paragraph align="left">
              Our interdisciplinary team leads this project to help reduce
              uncertainty and confusion caused by different predictions made
              available by various teams across the world. You can learn more
              about our team structure below and contact us via email at
              ensemble [at] gmu [dot] edu.
            </Typography>
          </Paper>
        </Grid>
        {members.map((d, i) => {
          return (
            <Grid key={i} item xs={12} sm={6} md={3}>
              <Card>
                <CardActionArea>
                  <CardContent>
                    <center>
                      <Avatar
                        alt={d.name}
                        src={`./static/images/avatar/${d.avatar}`}
                        className={classes.profile}
                      >
                        {d.initial}
                      </Avatar>
                    </center>
                    <Typography variant="subtitle1" gutterBottom>
                      {d.name}
                      {d.title !== undefined && `, ${d.title}`}
                      {d.superscript !== undefined && <sup>{d.superscript}</sup>}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {d.association}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions disableSpacing>
                  {d.dept !== undefined && (
                    <IconButton aria-label="Office" href={d.dept}>
                      <BusinessIcon />
                    </IconButton>
                  )}
                  {d.homepage !== undefined && (
                    <IconButton aria-label="Homepage" href={d.homepage}>
                      <HomeIcon />
                    </IconButton>
                  )}
                  {d.scholar !== undefined && (
                    <IconButton aria-label="Google Scholar" href={d.scholar}>
                      <SchoolIcon />
                    </IconButton>
                  )}
                  {d.linkedin !== undefined && (
                    <IconButton aria-label="LinkedIn" href={d.linkedin}>
                      <LinkedInIcon />
                    </IconButton>
                  )}
                  {d.twitter !== undefined && (
                    <IconButton aria-label="email" href={d.twitter}>
                      <TwitterIcon />
                    </IconButton>
                  )}
                  {d.email !== undefined && (
                    <IconButton
                      aria-label="email"
                      data-for="custom-event"
                      data-event="click"
                      data-event-off="mouseleave"
                      data-tip="Copied!"
                      onMouseDown={(e) => handleEmailClick(e, d.email)}
                    >
                      <EmailIcon />
                    </IconButton>
                  )}
                  {d.facebook !== undefined && (
                    <IconButton aria-label="facebook" href={d.facebook}>
                      <FacebookIcon />
                    </IconButton>
                  )}
                </CardActions>
              </Card>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="body1" paragraph align="left">
              * <Link href="https://assip.cos.gmu.edu/">2020 Aspiring Scientists' Summer Internship Program (ASSIP)</Link> 
              &nbsp;support is provided by College of Science, George Mason University.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <div id="placeholder"></div>
      <ReactTooltip id="custom-event" globalEventOff="click" />
    </Container>
  );
};
