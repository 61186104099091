import React from "react";
import {
  Grid,
  Avatar,
  Container,
  makeStyles,
  Typography,
  CardHeader,
  Paper,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  CardActionArea,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
    transform: "translateZ(0)",
  },
  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  icon: {
    color: "white",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  profile: {
    fontSize: "3rem",
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

const news = [
  {
    title: "Poster Presentation at the College of Science Undergraduate Research Colloquium",
    subtitle: "April 21, 2020",
    avatar: "headshot_zufle.jpg",
    image: "./static/images/urc_datadriven.png",
    image_title: "Data-driven",
    content: (
      <Typography variant="body1" gutterBottom align="left">
        Cecelia O'Neill presented our research at the{" "}
        at the College of Science Undergraduate Research Colloquium.<br />
        <i>
          Cecelia O'Neill, Justin Elarde, Joon-Seok Kim, Hamdi Kavak,
          Taylor Anderson and Andreas Züfle, “Latent Dirichlet Allocation for Data-Driven
          Mobility Behavior in Models of COVID-19 Spread”
        </i>
      </Typography>
    ),
  },
  {
    title: "Poster Presentation at the College of Science Undergraduate Research Colloquium",
    subtitle: "April 21, 2020",
    avatar: "headshot_anderson.jpg",
    image: "./static/images/urc_mobility.png",
    image_title: "Mobility-change",
    content: (
      <Typography variant="body1" gutterBottom align="left">
        Justin Elarde presented our research at the{" "}
        at the College of Science Undergraduate Research Colloquium.<br />
        <i>
          Justin Elarde, Joon-Seok Kim, Hamdi Kavak,
          Andreas Züfle and Taylor Anderson, “Analyzing Change in US Mobility
          Trends In Response to COVID-19”
        </i>
      </Typography>
    ),
  },
  {
    title: "Won 2021 GMU OSCAR Excellence Awards",
    subtitle: "April 14, 2020",
    avatar: "headshot_kavak.jpg",
    image: "./static/images/oscar_award_2021.png",
    image_title: "Oscar Awards",
    content: (
      <Typography variant="body1" gutterBottom align="left">
        GMU has announced this year’s OSCAR Excellence Awards, which recognize 
        students and faculty for academic excellence. We are proud to share that 
        Justin Elarde, one of our very own undergraduate students, is one of 
        this year’s awardee’s. We are also thrilled to hear that Dr. Hamdi Kavak 
        is one of this year’s faculty recipients.<br />
        <a href="https://science.gmu.edu/news/2021-gmu-oscar-excellence-awards">
        2021 GMU OSCAR Excellence Awards
        </a>
      </Typography>
    ),
  },
  {
    title: "Oral Presentation at ARIC 2020 Workshop",
    subtitle: "November 3, 2020",
    avatar: "headshot_zufle.jpg",
    image: "./static/images/data-driven.png",
    image_title: "Data-driven",
    content: (
      <Typography variant="body1" gutterBottom align="left">
        Our ASSIP students presented our paper at the{" "}
        <a href="https://urbands.github.io/aric2020/">
          3rd ACM SIGSPATIAL International Workshop on Advances in Resilient and
          Intelligent Cities
        </a>
        .<br />
        <i>
          John Pesavento, Andy Chen, Rayan Yu, Joon-Seok Kim, Hamdi Kavak,
          Taylor Anderson and Andreas Züfle, “Data Driven Mobility Models for
          COVID-19 Simulation,” In Proceedings of the 3rd ACM SIGSPATIAL
          Workshop on Advances in Resilient and Intelligent Cities (ARIC 2020),
          November 2020, pp. 29-38
        </i>{" "}
        <a href="https://doi.org/10.1145/3423455.3430305">
          https://doi.org/10.1145/3423455.3430305
        </a>
      </Typography>
    ),
  },
  {
    title: "Oral Presentation at COVID-2020 Workshop",
    subtitle: "October 24, 2020",
    avatar: "headshot_anderson.jpg",
    image: "./static/images/kim20covid.png",
    image_title: "Vision",
    content: (
      <Typography variant="body1" gutterBottom align="left">
        The newsletter article proposes a novel ensemble modeling approach that
        uses representative clustering to identify where existing model
        predictions of COVID-19 spread agree and unify these predictions into a
        smaller set of predictions. <br />
        <i>
          Joon-Seok Kim, Hamdi Kavak, Andreas Züfle, Taylor Anderson, “COVID-19
          Ensemble Models Using Representative Clustering,” SIGSPATIAL Special,
          July 2020, Volume 12, Issue 2, pp 33-41
        </i>
        &nbsp;
        <a href="https://doi.org/10.1145/3431843.3431848">
          https://doi.org/10.1145/3431843.3431848
        </a>
      </Typography>
    ),
  },
  {
    title: "Oral Presentation at COVID-2020 Workshop",
    subtitle: "November 3, 2020",
    avatar: "headshot_kavak.jpg",
    image: "./static/images/ensemble_vision.png",
    image_title: "Vision",
    content: (
      <Typography variant="body1" gutterBottom align="left">
        We shared our vision at the{" "}
        <a href="https://jiayuasu.github.io/covid19-workshop/">
          1st ACM SIGSPATIAL International Workshop on Modeling and
          Understanding the Spread of COVID-19
        </a>
        .
      </Typography>
    ),
  },
  {
    title: "Organization of COVID-2020 Workshop",
    subtitle: "November 3, 2020",
    avatar: "headshot_zufle.jpg",
    image: "./static/images/covid-2020.png",
    image_title: "COVID-2020 Workshop",
    content: (
      <Typography variant="body1" gutterBottom align="left">
        We organize the{" "}
        <a href="https://jiayuasu.github.io/covid19-workshop">
          COVID-2020 Workshop
        </a>{" "}
        in conjunction of ACM SIGSPATIAL to reach out the SIGSPATIAL community
        as part of our project.
      </Typography>
    ),
  },
  {
    title: "Won 4th Place (SIGKDD PAPW 2020)",
    subtitle: "Sep 1, 2020",
    avatar: "headshot_kim.jpg",
    image: "./static/images/PAPW_win.png",
    image_title: "Certificate",
    content: (
      <Typography variant="body1" gutterBottom align="left">
        Our team won the Challenge on Mobility Intervention for Epidemics in the
        1st ACM SIGKDD International Workshop on Prescriptive Analytics for the
        Physical World (PAPW 2020) with the solution, titled "Expert-in-the-Loop
        Prescriptive Analytics using Mobility Intervention for Epidemics."
      </Typography>
    ),
  },
  {
    title: "COS News",
    subtitle: "May 29, 2020",
    avatar: "headshot_anderson.jpg",
    image: "./static/images/news-cos.png",
    image_title: "RAPID",
    content: (
      <Typography variant="body1" gutterBottom align="left">
        Our proposed research has been reported by George Mason University’s
        &nbsp;
        <a href="https://science.gmu.edu/news/nsf-rapid-funding-allows-mason-scientists-find-consensus-between-covid-19-predictions">
          COS News
        </a>
      </Typography>
    ),
  },
  {
    title: "NSF RAPID Awarded",
    subtitle: "May 15, 2020",
    avatar: "headshot_anderson.jpg",
    image: "./static/images/nsf.png",
    image_title: "RAPID",
    content: (
      <Typography variant="body1" gutterBottom align="left">
        RAPID: An Ensemble Approach to Combine Predictions from COVID-19
        Simulations Our research project abstract is available on the NSF
        website &nbsp;{" "}
        <a href="https://www.nsf.gov/awardsearch/showAward?AWD_ID=2030685">
          https://www.nsf.gov/awardsearch/showAward?AWD_ID=2030685
        </a>
      </Typography>
    ),
  },
];

export default () => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h4" gutterBottom>
              News & Updates
            </Typography>
          </Paper>
        </Grid>
        {news.map((d, i) => {
          return (
            <Grid key={i} item xs={6}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar
                      aria-label="recipe"
                      className={classes.avatar}
                      src={`./static/images/avatar/${d.avatar}`}
                    />
                  }
                  action={
                    <IconButton aria-label="settings">
                      <MoreVertIcon />
                    </IconButton>
                  }
                  title={d.title}
                  subheader={d.subtitle}
                />
                <CardMedia
                  className={classes.media}
                  image={d.image}
                  title={d.image_title}
                />
                <CardActionArea>
                  <CardContent>{d.content}</CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};
