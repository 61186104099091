import React from 'react';
import { 
    Grid, 
    Container, 
    Typography,
    Paper,
    makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
      paddingTop: 10,
      paddingBottom: 10,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'justify',
      color: theme.palette.text.secondary,
    },
  }));
  
 export default () => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h4" gutterBottom align='center'>Contact Us</Typography>
            <Typography variant="body1" gutterBottom paragraph>
              We are always happy to chat if you have any questions or feedback. 
              Feel free to send us an email at ensemble [at] gmu [dot] edu
              or check our <Link to="/Team">Team</Link> page
              to contact our team members individually.
            </Typography>
            <img 
              src={"./static/images/exploratory_hall.jpg"} 
              width="100%"
              alt="Exploratory Hall" />
            <Typography variant="caption" gutterBottom align='center'>
              Exploratory Hall, (Image source):
              https://photo.gmu.edu/CampusBuildingsLandmarksandFac/Fairfax-Campus/i-tSGPZgP
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
