import React from 'react';
import { 
    Grid, 
    Container, 
    Typography,
    Paper,
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      paddingTop: 10,
      paddingBottom: 10,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'justify',
      color: theme.palette.text.secondary,
    },
  }));
  
 export default () => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h4" gutterBottom align='center'>
                Ensemble Approach
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
            The model alignment step consists of the following:
            </Typography>
            <Typography variant="body1" gutterBottom component={'span'}>
            <ol>
            <li>
            Select a range of open-source and publicly available 
            COVID-19 simulation models (e.g., mathematical, curve-fitting, 
            and agent-based models);
            </li>
            <li>
            Select parameters related to COVID-19 transmission, 
            the geographic scale/region, and policy interventions. 
            We will use the latest parameter values, as needed, obtained 
            from authoritative, evidence-based sources; 
            </li>
            <li>
            Run the models, as needed, with parameters identified in 
            step 2 and make sure the output is correctly parsed to be 
            published on the dashboard. This step involves creating a software
            protocol to parse different outputs generated by different models.
            </li>
            <li>
            After model alignment, we will have obtained a broad set of 
            predictions, each corresponding to different “possible worlds” 
            generated from different models, different parameters, and different 
            runs. Each possible world consists of a number of time 
            series data, corresponding to the predicted number of 
            new cases, number of recoveries, number of deaths, 
            and other quantities of interest. 
            </li>
            </ol>
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
            These time series are fed into the representative clustering step,
            which consists of the following:
            </Typography>
            <Typography variant="body1" gutterBottom component={'span'}>
            <ol>
            <li>
            Map each prediction into a low dimensional feature space, 
            using feature reduction techniques such as Non-Negative 
            Matrix Factorization. Within this feature space, we investigate
            appropriate clustering algorithms to find the highest quality clusters;
            </li>
            <li>
            Apply inductive statistics to estimate the probability that 
            a cluster represents the unknown true future. By leveraging 
            existing solutions for clustering uncertain spatial data, 
            we will, initially, give each simulation run an equal weight, 
            thus assuming that each simulation result has the same probability
            to capture reality. As the project continues, we will use 
            supervised learning to reinforce the weights of those models 
            and parameters, yielding the most accurate predictions;
            </li>
            <li>
            Finally, choose a representative for each cluster to be
            visualized on the dashboard. This cluster should minimize 
            the pair-wise distance in the feature space to other simulation
            runs within the same cluster. 
            </li>
            </ol>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
