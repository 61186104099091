import React, { useEffect } from "react";
import { Typography, Link } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import { useLocation } from "react-router-dom";
import history from "./utils/history";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const menu = [
  {
    label: "Dashboard",
    url: "/",
  },
  {
    label: "Models",
    url: "/Models",
  },
  // {
  //   label: "Ensemble Approach",
  //   url: "/Ensemble",
  // },
  {
    label: "News & Updates",
    url: "/News",
  },
  {
    label: "Team",
    url: "/Team",
  },
  {
    label: "Publications",
    url: "/Publications",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "0",
  },
}));

const JoonsTabs = withStyles({
  root: {
    //borderBottom: '3px solid #2e1534',
    //backgroundColor: '#2e1534',
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      width: "100%",
      backgroundColor: "#635ee7",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export default (props) => {
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const [locationKeys, setLocationKeys] = React.useState([]);

  useEffect(() => {
    findTab(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          // Handle forward event
          findTab(history.location.pathname);
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          // Handle back event
          findTab(history.location.pathname);
        }
      }
    });
  }, [locationKeys]);

  function findTab(path) {
    const tab = menu.find((e) => e.url === path);
    if (tab !== undefined) setValue(menu.indexOf(tab));
    //else setValue(-1);
  }

  function move(tab) {
    setValue(tab);
    history.push(menu[tab].url);
  }

  const handleClickHome = (e) => {
    if (e) {
      e.preventDefault();
    }
    move(0);
  };

  const handleChange = (event, newValue) => {
    move(newValue);
  };
  const classes = useStyles();
  const tabs = menu.map((d, i) => {
    return (
      <Tab
        key={i}
        label={d.label}
        {...a11yProps(i)}
        classes={{
          root: classes.root,
        }}
      />
    );
  });
  return (
    <AppBar position="sticky" elevation={0} style={{ background: "#2E3B55" }}>
      <Toolbar>
        <Grid justify={"space-between"} container>
          <Grid item>
            <Typography variant="h5">
              <Link
                color="inherit"
                underline="none"
                component="button"
                onMouseDown={handleClickHome}
              >
                <img
                  src={"./static/images/nocovid-logo.png"}
                  alt="Logo"
                  height="50"
                />
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <JoonsTabs
              variant="scrollable"
              scrollButtons="on"
              value={value}
              onChange={handleChange}
              aria-label="Navigation"
            >
              {tabs}
            </JoonsTabs>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
