import React from "react";

import {
  Typography,
  IconButton,
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import ReactTooltip from "react-tooltip";

import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import TwitterIcon from "@material-ui/icons/Twitter";
import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";
import ContactMailIcon from "@material-ui/icons/ContactMail";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  icon: {
    color: "white",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  profile: {
    fontSize: "3rem",
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  logo: {
    padding: theme.spacing(2),
  },
  footer: {
    background: "#2E3B55",
    // top: 'auto',
    // bottom: 0
  },
}));

export default (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleEmailClick = (e, email) => {
  //   if (document.queryCommandSupported("copy")) {
  //     const textField = document.createElement("textarea");
  //     textField.innerText = email;
  //     const parentElement = document.getElementById("placeholder");
  //     parentElement.appendChild(textField);
  //     textField.select();
  //     document.execCommand("copy");
  //     parentElement.removeChild(textField);
  //   }
  // };
  return (
    <AppBar position="sticky" className={classes.footer}>
      <Container>
        <Grid justify={"space-between"} container>
          <Grid item className={classes.logo}>
            <a href="https://www2.gmu.edu">
              <img
                src={"./static/images/mason-logo.png"}
                height={70}
                alt="George Mason University"
              />
            </a>{" "}
            &nbsp;
            <img
              src={"./static/images/CSC-logo-2-condensed.png"}
              height={70}
              alt="Center for Social Complexity"
            />{" "}
            &nbsp;
            <img
              src={"./static/images/nsf_logo_bottom.png"}
              height={70}
              alt="National Science Foundation"
            />
          </Grid>
          <Grid item className={classes.logo}>
            <Toolbar justify={"space-between"}>
              <ButtonGroup
                variant="text"
                color="inherit"
                aria-label="text primary button group"
              >
                <Button href="/Contact">
                  <ContactMailIcon /> &nbsp; Contact Us
                </Button>
                <Button>Licences</Button>
                <Button onClick={handleClickOpen}>Disclaimer</Button>
              </ButtonGroup>
            </Toolbar>
          </Grid>
        </Grid>
        <Grid justify={"space-between"} container>
          <Grid item className={classes.logo}>
            <Typography variant="overline" paragraph align="left">
              George Mason University
              <br />
              4400 University Dr. Fairfax, VA 22030
            </Typography>
          </Grid>
          {/* <Grid item className={classes.logo}>
            <Toolbar justify={"space-between"}>
              <IconButton aria-label="email"
                      data-for="custom-event"
                      data-event="click"
                      data-event-off="mouseleave"
                      data-tip="Copied!"
                      onMouseDown={(e) => handleEmailClick(e, 'ensemble@gmu.edu')}>
                <EmailIcon fontSize="large" />
              </IconButton>
              <IconButton>
                <FacebookIcon fontSize="large" />
              </IconButton>
              <IconButton>
                <TwitterIcon fontSize="large" />
              </IconButton>
              <IconButton>
                <ShareIcon fontSize="large" />
              </IconButton>
              <IconButton>
                <FavoriteIcon fontSize="large" />
              </IconButton>
            </Toolbar>
          </Grid> */}
        </Grid>
        {/* <div id="placeholder"></div>
        <ReactTooltip id="custom-event" globalEventOff="click" /> */}
      </Container>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">Disclaimer</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText>
            <Typography variant="body1" align="justify" gutterBottom paragraph>
              The COVID-19 Ensemble Dashboard includes data obtained from model
              predictions provided by research teams across the world. Please
              visit our <Link to="/Models">Models</Link> page for an overview of
              each model and the associated data licenses. We use this publicly
              available data to find the consensus between the models, described
              in the Ensemble Approach page. Our
              team makes the best effort to provide useful content, but assumes
              no responsibility or liability for accuracy, errors, or omissions
              in the data provided or linked through this website.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
};
