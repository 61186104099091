import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import HelpIcon from "@material-ui/icons/Help";
import { Container, Grid, StepContent } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  appBar: {
    position: "relative",
    backgroundColor: "#2E3B55",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    overflow: "hidden",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getSteps() {
  return [
    "Overall Layout",
    "Interactive Map Interface",
    "Maps",
    "Forecast Date",
    "State level prediction",
    "Ground truth and prediction",
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return (
        <div>
          <img src="./static/images/dashboard-tour0.png" />
          <Typography>
            Dashboard consists of a model selector, an interactive map
            interface, a chart for a state level forecast, and a chart for
            comparison between ground truth data and a forecast of model.
          </Typography>
        </div>
      );
    case 1:
      return (
        <div>
          <img src="./static/images/dashboard-tour1.gif" />
          <Typography>
            You can move your cursor on a state to see information on the map.
            You can click a state to see more detail on each chart. In order to
            go back to the US level, simply click outside of US. In order to
            enable/disable animation of a forecast, click the play/pause buttons
            on the top of the map.
          </Typography>
        </div>
      );
    case 2:
      return (
        <div>
          <img src="./static/images/dashboard-tour0.png" />
          <Typography>
            Each model may have a range of prediction with uncertainty. To see
            other quantiles of the model forecast, you can change the quantile
            using the Quatile Selector on the right of the map.
          </Typography>
        </div>
      );
    case 3:
      return (
        <div>
          <img src="./static/images/dashboard-tour0.png" />
          <Typography>
            The Forecast Date Selector shows available forecast dates for the
            selected model.
          </Typography>
        </div>
      );
    case 4:
      return (
        <div>
          <img src="./static/images/dashboard-tour0.png" />
          <Typography>
            If the model provides state level forecasts, this chart will show
            you trends within the forecast horizon. Note that a curve between
            two consecutive points is estimated for smooth visualization. To see
            a stacked view, you can enable the stacked slider button on the top
            left of the chart.
          </Typography>
        </div>
      );
    case 5:
      return (
        <div>
          <img src="./static/images/dashboard-tour0.png" />
          <Typography>
            This chart shows ground truth data and forecast of the selected
            model. If you select a state on the interactive map interface, the
            chart will illustrate a forecast and ground truth of the selected
            state. A curve between two consecutive points is estimated for
            smooth visualization. By brushing (selecting a period), you can zoom
            in. To reset it, click the button on the top right.
          </Typography>
        </div>
      );
    default:
      return "Unknown step";
  }
}

export default function FullScreenDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const steps = getSteps();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
        <HelpIcon fontSize="large" />
      </IconButton>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        // style={{ opacity: 0.9 }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Dashboard Tour
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Got it
            </Button>
          </Toolbar>
        </AppBar>
        <Container maxWidth="md">
          <Grid container spacing={3} className={classes.container}>
            <Grid item xs={12}>
              <div className={classes.root}>
                <Stepper
                  nonLinear
                  activeStep={activeStep}
                  orientation="vertical"
                >
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepButton
                        onClick={handleStep(index)}
                        completed={completed[index]}
                      >
                        {label}
                      </StepButton>
                      <StepContent>
                        {getStepContent(index)}
                        <div className={classes.actionsContainer}>
                          <div>
                            <Button
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              className={classes.button}
                            >
                              Back
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleNext}
                              className={classes.button}
                            >
                              Next
                            </Button>
                            {activeStep !== steps.length &&
                              (completed[activeStep] ? (
                                <Typography
                                  variant="caption"
                                  className={classes.completed}
                                >
                                  Step {activeStep + 1} already completed
                                </Typography>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleComplete}
                                  className={classes.button}
                                >
                                  {completedSteps() === totalSteps() - 1
                                    ? "Finish"
                                    : "Complete Step"}
                                </Button>
                              ))}
                          </div>
                        </div>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
                {allStepsCompleted() && (
                  <div>
                    <Typography className={classes.instructions}>
                      All steps are completed!
                    </Typography>
                    <Button onClick={handleReset}>Reset</Button>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </div>
  );
}
