import React from "react";
// import useChartConfig from "./hooks/useChartConfig";
import { Chart } from "react-charts";
import { LocationToName } from "./LocationName";

export default ({
  elementType,
  activeDatumIndex,
  activeSeriesIndex,
  targetDate,
  asDate,
  setState,
  originalData,
  quantile,
  stacked = false,
  location,
}) => {
  // const { data } = useChartConfig({
  //   series: 10,
  //   height: 100,
  //   // grouping: 'primary',
  //   // dataType: 'ordinal',
  //   // show: ['elementType']
  // })

  const data = React.useMemo(
    (data) => {
      const tmp = originalData
        .filter(
          (v) =>
            (location !== null
              ? location.id === "US"
                ? true
                : v.location === location.id
              : true) &&
            Math.abs(v.quantile - quantile) <= 0.001 &&
            // v.target.includes("wk") &&
            LocationToName[v.location]
        )
        .map((v, i) => {
          return {
            x: new Date(v.target_end_date),
            y: v.value,
            location: v.location,
            label: LocationToName[v.location],
          };
        })
        .reduce((rv, x) => {
          (rv[x.location] = rv[x.location] || []).push(x);
          return rv;
        }, {});
      const tmp2 = Object.entries(tmp).map((v, i) => {
        return { label: v[1][0].label, data: v[1] };
      });
      return tmp2;
    },
    [originalData, quantile, location]
  );

  // console.log(data, quantile);
  // Use data.lines[n].data to represent the different datums for each series
  // const getDatums = React.useCallback((series) => {
  //   console.log(series);
  //   return series.data;
  // }, []);

  // Use the original data object and the datum index to reference the datum's primary value.
  // const getPrimary = React.useCallback(
  //   (datum, i, series, seriesIndex, data) => datum[i], //.find()axis[i],
  //   []
  // );

  // Use data.lines[n].data[n].value as each datums secondary value
  // const getSecondary = React.useCallback((datum) => datum.value, []);

  // const series = React.useMemo(
  //   () => ({
  //     type: elementType,
  //   }),
  //   [elementType]
  // );
  const axes = React.useMemo(
    () => [
      {
        primary: true,
        type: "utc",
        position: "bottom",
      },
      {
        type: "linear",
        position: "right",
        stacked: stacked,
      },
    ],
    [stacked]
  );
  const getSeriesStyle = React.useCallback(
    (series) => ({
      // color: `url(#${series.index % 4})`,
      transition: "all .5s ease",
      opacity:
        activeSeriesIndex > -1
          ? series.index === activeSeriesIndex
            ? 1
            : 0.3
          : 0.3,
    }),
    [activeSeriesIndex]
  );
  const getDatumStyle = React.useCallback(
    (datum) => ({
      r:
        activeDatumIndex === datum.index &&
        activeSeriesIndex === datum.seriesIndex
          ? 7
          : activeDatumIndex === datum.index
          ? 5
          : datum.series.index === activeSeriesIndex
          ? 3
          : datum.otherHovered
          ? 2
          : 2,
    }),
    [activeDatumIndex, activeSeriesIndex]
  );
  const onFocus = React.useCallback(
    (focused) => {
      // console.log("focused", focused);
      setState({
        activeSeriesIndex: focused ? focused.series.id : -1,
        activeDatumIndex: focused ? focused.index : -1,
        targetDate: targetDate
      }); 
    },
    [setState, targetDate]
  );

  const handleClick = React.useCallback(
    (clicked) => {
      // console.log("click", clicked.primary)
      if (clicked?.primary !== undefined)
        setState({
          targetDate: clicked.primary,
        });
    },
    [setState]
  );

  const primaryCursor = React.useMemo(
    () => ({
      value: asDate,
    }),
    [asDate]
  );

  return (
    <>
      <div style={{ height: 550 }}>
        <Chart
          data={data}
          // getSeries={getSeries}
          // getDatums={getDatums}
          // getPrimary={getPrimary}
          // getSecondary={getSecondary}
          // grouping={grouping}
          // series={series}
          axes={axes}
          getSeriesStyle={getSeriesStyle}
          getDatumStyle={getDatumStyle}
          onFocus={onFocus}
          onClick={handleClick}
          tooltip
          primaryCursor={primaryCursor}
        />
      </div>
    </>
  );
};
