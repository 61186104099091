import React, { useState, useEffect } from "react";
import "./App.css";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import FastForwardIcon from "@material-ui/icons/FastForward";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { useCookies } from "react-cookie";

import USMap from "./USMap";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {
  Typography,
  Container,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  FormGroup,
  Backdrop,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomChart from "./CustomChart";
import { LocationTable } from "./LocationName";
import ComparisonChart from "./ComparisonChart";
import DateSelection from "./DateSelection";
import Tour from "./Tour";

const { REACT_APP_API_HOST } = process.env;

const useStyles = makeStyles((theme) => ({
  rootHorizon: {
    height: 50,
    width: "100%",
  },
  rootVertical: {
    minHeight: 200,
    maxHeight: 500,
    textAlign: "right",
  },
  label: {
    transform: `translateX(-50%) 
    translateY(-50%) 
    rotate(45deg) 
    translateX(50%)`,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  container: {
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    overflow: "hidden",
    // alignItems: "center",
    backgroundColor: theme.palette.background.paper,
  },
}));

const incList = [
  { name: "cum", label: "cumulative" },
  { name: "inc", label: "incident" },
];

const targetTypeList = [
  { name: "death", label: "Number of deaths" },
  { name: "hosp", label: "Number of hospitalizations" },
];

// const stackList = [
//   { label: "yes", value: true },
//   { label: "no", value: false },
// ];

const dateTypeList = [
  { label: "day", value: "day" },
  { label: "week", value: "wk" },
];

function valuetext(value) {
  const v = Math.round(value * 100);
  if (v % 10 === 0) return `${v} Quantile`;
  return "";
}

function Dashboard() {
  // const [cookies, setCookie] = useCookies(["tour"]);
  const [quantile, setQuantile] = useState(0.5);
  const [quantiles, setQuantiles] = useState(null);
  const [{ minQuantile, maxQuantile }, setMinMaxQuantiles] = useState({
    minQuantile: 0,
    maxQuantile: 1,
  });
  const [dateType, setDateType] = useState("wk");
  const [hover, setHover] = useState(null);
  const [forecastDate, setForecastDate] = useState();
  const [asDate2, setAsDate2] = useState();
  const [forecastDates, setForecastDates] = useState(null);
  const [forecastDateIndex, setForecastDateIndex] = useState(0);
  const [date, setDate] = useState();
  const [asDate, setAsDate] = useState();
  const [dates, setDates] = useState(null);
  const [dateIndex, setDateIndex] = useState(0);
  const [play, setPlay] = React.useState("pause");
  const [dataTable, setDataTable] = useState(null);
  const [playInterval, setPlayInterval] = useState(1000);
  const [model, setModel] = useState(null);
  const [deathCumGT, setDeathCumGT] = useState([]);
  const [deathIncGT, setDeathIncGT] = useState([]);
  const [incident, setIncident] = useState(false);
  const [targetType, setTargetType] = useState("death");
  const [modelList, setModelList] = useState([]);
  const [metadata, setMetadata] = useState([]);
  const [stacked, setStacked] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [location, setLocation] = useState(LocationTable[0]);
  const [
    { activeSeriesIndex, activeDatumIndex, targetDate },
    setChartState,
  ] = React.useState({
    activeSeriesIndex: -1,
    activeDatumIndex: -1,
    targetDate: null,
  });
  const [
    { activeSeriesIndex2, activeDatumIndex2, targetDate2 },
    setForecastState,
  ] = React.useState({
    activeSeriesIndex2: -1,
    activeDatumIndex2: -1,
    targetDate2: null,
  });
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    let source = axios.CancelToken.source();
    fetchModels(source.token);
    fetchGroundTruth(source.token);
    return () => {
      source.cancel();
    };
  }, []);

  const fetchModels = async (token) => {
    console.log("Fetching model list from the server ...");
    try {
      const res = await axios.get(`${REACT_APP_API_HOST}api/models`, {
        cancelToken: token,
      });
      setMetadata(res.data);
      setModelList(
        res.data.map((item, index) => {
          return item._id;
        })
      );
      setModel(res.data[0]._id);
      console.log("loaded model list");
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("canceled");
      } else {
        setModelList([]);
        console.log(err);
      }
    }
  };

  const fetchGroundTruth = async (token) => {
    console.log("Fetching ground truth from the server ...");
    try {
      let res = await axios.get(
        `${REACT_APP_API_HOST}api/death?model=gt&inc=false`,
        {
          cancelToken: token,
        }
      );
      let sortedDates = res.data.sort((a, b) => {
        const c = new Date(a.timezero);
        const d = new Date(b.timezero);
        if (c > d) return 1;
        if (c < d) return -1;
        return 0;
      });
      setDeathCumGT(sortedDates);
      res = await axios.get(
        `${REACT_APP_API_HOST}api/death?model=gt&inc=true`,
        {
          cancelToken: token,
        }
      );
      sortedDates = res.data.sort((a, b) => {
        const c = new Date(a.timezero);
        const d = new Date(b.timezero);
        if (c > d) return 1;
        if (c < d) return -1;
        return 0;
      });
      setDeathIncGT(sortedDates);
      console.log("loaded ground truth");
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("canceled");
      } else {
        setDeathCumGT([]);
        setDeathIncGT([]);
        console.log(err);
      }
    }
  };

  useEffect(() => {
    // console.log("update", incident);
    updateForecast();
  }, [model, incident, targetType]);

  useEffect(() => {
    let source = axios.CancelToken.source();
    if (model && targetType && forecastDate) {
      fetchData(source.token);
    }
    return () => {
      source.cancel();
    };
  }, [model, incident, targetType, forecastDate, dateType]);

  useEffect(() => {
    let interval = null;
    if (play !== "pause") {
      interval = setInterval(() => {
        if (dates) setIndex((dateIndex + 1) % dates.length);
      }, playInterval);
    } else if (play === "pause") {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [play, dateIndex, playInterval]);

  useEffect(() => {
    setAsDate(new Date(date));
  }, [date]);

  useEffect(() => {
    setAsDate2(new Date(forecastDate));
  }, [forecastDate]);

  useEffect(() => {
    if (targetDate) {
      const txt = targetDate.toISOString().substring(0, 10);
      const v = dates.find((v) => v.label === txt);
      if (v !== undefined) setIndex(v.value);
    }
  }, [targetDate]);

  useEffect(() => {
    if (targetDate2) {
      const txt = targetDate2.toISOString().substring(0, 10);
      const v = forecastDates.find((v) => v.label === txt);
      if (v !== undefined) setForecastIndex(v.value);
    }
  }, [targetDate2]);

  const updateForecast = () => {
    if (metadata.length === 0) {
      console.log("Loading ...");
      return;
    }

    if (model === null) {
      setDataTable(null);
      console.log("no model selected");
      return;
    }

    if (!targetType) {
      setDataTable(null);
      console.log("no target type selected");
      return;
    }
    // console.log(metadata);

    const queryTarget = metadata.find((item) => item._id.model === model.model);

    const tmp = queryTarget.byTargetType.find(
      (item) =>
        item.targetType === targetType &&
        (item.inc === incident || item.cum !== incident)
    );

    if (!tmp) {
      setDataTable(null);
      console.log("no data");
      return;
    }

    // console.log(tmp);

    const sortedDates = tmp.byForecastDates.sort((a, b) => {
      const c = new Date(a.forecast_date);
      const d = new Date(b.forecast_date);
      if (c > d) return 1;
      if (c < d) return -1;
      return 0;
    });

    // console.log(sortedDates);

    const availableDates = sortedDates.map((v, i) => {
      return { value: i, label: v.forecast_date };
    });

    // console.log("updated", availableDates);
    if (availableDates.length > 0) {
      const lastDay = availableDates.length - 1;
      setForecastDates(availableDates);
      setForecastIndex(lastDay);
      setForecastDate(availableDates[lastDay].label);
    }
  };

  const fetchData = async (token) => {
    try {
      setOpen(true);
      setDataTable([]);
      setDate(null);
      setDates(null);

      console.log("Fetching data from the server ...");

      var res;
      if (targetType === "death") {
        const qString = `${REACT_APP_API_HOST}api/death?model=${model.model}&forecast_date=${forecastDate}&inc=${incident}&date_type=${dateType}`;
        console.log(qString);
        res = await axios.get(qString, { cancelToken: token });
      } else if (targetType === "hosp") {
        const qString = `${REACT_APP_API_HOST}api/hospitalization?model=${model.model}&forecast_date=${forecastDate}&inc=${incident}&date_type=${dateType}`;
        console.log(qString);
        res = await axios.get(qString, { cancelToken: token });
      } else {
        // console.log(targetType);
        res = { data: [] };
      }

      if (res.data.length === 0) {
        setDataTable(["no data"]);
      } else {
        setDataTable(res.data);
      }
      const allDates = res.data.reduce(function (accumulator, currentValue) {
        if (accumulator.indexOf(currentValue.target_end_date) === -1) {
          accumulator.push(currentValue.target_end_date);
        }
        return accumulator;
      }, []);
      const targetDates = allDates.sort();
      const labels = targetDates.map((v, i) => {
        return { value: i, label: v };
      });
      if (labels.length > 0) {
        setDates(labels);
        setIndex(0);
        setDate(labels[0].label);
      }

      const allQuantiles = res.data.reduce(function (
        accumulator,
        currentValue
      ) {
        if (accumulator.indexOf(currentValue.quantile) === -1) {
          accumulator.push(currentValue.quantile);
        }
        return accumulator;
      },
      []);

      // console.log(allQuantiles);

      const targetQuantiles = allQuantiles.sort();
      const quantileLabels = targetQuantiles.map((v, i) => {
        return { value: v, quantile: v, label: valuetext(v) };
      });

      if (quantileLabels.length > 0) {
        setMinMaxQuantiles({
          minQuantile: quantileLabels[0].value,
          maxQuantile: quantileLabels[quantileLabels.length - 1].value,
        });

        const middle = Math.floor(quantileLabels.length / 2);
        if (quantileLabels.length > 1) {
          quantileLabels[0].label = <strong>Best case</strong>;
          quantileLabels[quantileLabels.length - 1].label = (
            <strong>Worst case</strong>
          );
        }
        setQuantiles(quantileLabels);
        setQuantile(quantileLabels[middle].quantile);
      }

      console.log("downloaded");
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log("canceled");
      } else {
        setDataTable(null);
        console.log(err);
      }
    }
  };

  const modelProps = {
    options: modelList,
    getOptionLabel: (option) => option.model,
  };

  const typeProps = {
    options: incList,
    getOptionLabel: (option) => option.label,
  };

  // const targetTypeProps = {
  //   options: targetTypeList,
  //   getOptionLabel: (option) => option.label,
  // };

  const dateTypelProps = {
    options: dateTypeList,
    getOptionLabel: (option) => option.label,
  };

  const setIndex = (index) => {
    setDateIndex(index);
    if (dates) {
      const matched = dates.find((item) => item.value === index);
      if (matched !== undefined) setDate(matched.label);
    }
  };

  const handleStack = (e, value) => {
    setStacked(value);
  };

  const setForecastIndex = (index) => {
    setForecastDateIndex(index);
    if (forecastDates) {
      const matched = forecastDates.find((item) => item.value === index);
      if (matched !== undefined) setForecastDate(matched.label);
    }
  };

  const handlePlay = (event, playSetting) => {
    if (playSetting) {
      setPlay(playSetting);
      setPlayInterval(playSetting === "fast" ? 200 : 1000);
    }
  };

  const handleChangeQuantile = (e, v) => {
    setQuantile(v);
  };

  const handleSelectState = (v) => {
    // console.log(v)
    if (v) {
      setSelectedState(v);
      setLocation(LocationTable.find((el, i) => el.id === v.location));
      // console.log(v);
    }
  };

  const handleChangeForecastDate = (e, v) => {
    setForecastIndex(v);
  };
  // const handleChangeDate = (e, v) => {
  //   setIndex(v);
  // };
  const onHover = (d) => {
    setHover(d);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleTour = (tour) => {
  //   setCookie("tour", tour, { path: "/" });
  // };

  const classes = useStyles();
  const mapBackStyle = { padding: 0, background: "#fefefe" };
  return (
    <Container maxWidth="xl">
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            {...modelProps}
            id="modelChooser"
            disableClearable
            onChange={(event, newValue) => {
              setModel(newValue);
            }}
            value={model}
            renderInput={(params) => (
              <TextField {...params} label="Model" margin="normal" />
            )}
          />
        </Grid>
        {/* <Grid item xs={12} sm={3}>
          <Autocomplete
            {...targetTypeProps}
            id="targetTypeChooser"
            disableClearable
            onChange={(event, newValue) => {
              setTargetType(newValue.name);
            }}
            //defaultValue={targetTypeList[0]}
            renderInput={(params) => (
              <TextField {...params} label="Target type" margin="normal" />
            )}
          />
        </Grid> */}
        <Grid item xs={12} sm={2}>
          <Autocomplete
            {...typeProps}
            id="incidentChooser"
            disableClearable
            onChange={(event, newValue) => {
              newValue.name === "cum" ? setIncident(false) : setIncident(true);
            }}
            defaultValue={incList[0]}
            renderInput={(params) => (
              <TextField {...params} label="Type" margin="normal" />
            )}
          />
        </Grid>
        {/* <Grid item xs={12} sm={2}>
          <Autocomplete
            {...dateTypelProps}
            id="dateTypeChooser"
            disableClearable
            onChange={(event, newValue) => {
              setDateType(newValue.value);
            }}
            defaultValue={dateTypeList[1]}
            renderInput={(params) => (
              <TextField {...params} label="Day/Week" margin="normal" />
            )}
          />
        </Grid> */}
        <Grid item xs={1} sm={1}>
          <Tour />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">
            {model === null ? "Prediction model" : model.model}
          </Typography>

          {dataTable?.length === 1 &&
            dataTable[0] === "no data" &&
            "No data available"}

          {dataTable?.length === 0 && (
            // <div width="100%" height="100">
            //   <CircularProgress style={{ position: "absolute" }} />
            // </div>
            <Backdrop
              className={classes.backdrop}
              open={open}
              onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}

          {!dataTable && (
            <Typography variant="h5" color="error">
              The data server is temporarily not accessible. Please try again.
            </Typography>
          )}
        </Grid>
        <Grid item lg={5} xs={10} style={mapBackStyle}>
          {dates && (
            <ToggleButtonGroup
              style={{ position: "absolute", margin: "5px 5px" }}
              exclusive
              value={play}
              onChange={handlePlay}
              aria-label="Play Group"
            >
              <ToggleButton value="play" aria-label="play">
                <PlayCircleOutlineIcon fontSize="small" />
              </ToggleButton>
              {/* <ToggleButton value="fast" aria-label="fast">
                <FastForwardIcon fontSize="small" />
              </ToggleButton> */}
              <ToggleButton value="pause" aria-label="pause">
                <PauseCircleOutlineIcon fontSize="small" />
              </ToggleButton>
            </ToggleButtonGroup>
          )}
          <USMap
            hoverElement={hover}
            selectedElement={selectedState}
            onHover={onHover}
            onClick={handleSelectState}
            quantile={quantile}
            date={date}
            data={dataTable}
            targetType={targetType}
            incident={incident}
            forecastDate={forecastDate}
          />
          {forecastDates && <Typography>Forecast Date</Typography>}
          {forecastDates?.length === 1 && (
            <Typography>{forecastDate}</Typography>
          )}
          {forecastDates?.length > 1 && (
            // <Slider
            //   classes={{
            //     root: classes.rootHorizon,
            //     markLabel: classes.label,
            //   }}
            //   onChangeCommitted={handleChangeForecastDate}
            //   min={0}
            //   max={forecastDates.length - 1}
            //   marks={forecastDates}
            //   track={false}
            //   valueLabelDisplay="off"
            //   step={1}
            //   value={forecastDateIndex}
            //   // defaultValue={0}
            // />
            <DateSelection
              setState={setForecastState}
              activeDatumIndex={activeDatumIndex2}
              activeSeriesIndex={activeSeriesIndex2}
              originalData={forecastDates}
              asDate={asDate2}
              targetDate={targetDate2}
            />
          )}
        </Grid>
        <Grid
          item
          lg={1}
          xs={2}
          // classes={{
          //   root: classes.rootVertical,
          // }}
          style={{ background: "#ffffff" }}
        >
          {dates && quantiles && (
            <Slider
              orientation="vertical"
              classes={{
                root: classes.rootVertical,
                // markLabel: classes.label,
              }}
              onChangeCommitted={handleChangeQuantile}
              min={minQuantile}
              max={maxQuantile}
              marks={quantiles}
              track={false}
              valueLabelDisplay="off"
              step={null}
              value={quantile}
              // value={quantileIndex}
              // defaultValue={0}
            />
          )}
        </Grid>
        {/* <Grid item xs={11}>
          {forecastDates && 
          <Typography>Prediction date</Typography>
          }
          {dates && 
          <Slider 
            classes={{ 
                root: classes.rootHorizon,
                markLabel: classes.label,
            }}
            onChangeCommitted={handleChangeDate} 
            min={0} max={dates.length-1} 
            marks={dates}
            track={false} 
            valueLabelDisplay="off"
            step={1}
            value={dateIndex}
            defaultValue={0} 
          />}
        </Grid> */}
        {/* <Grid item>
          <PredictionContent /></Grid> */}
        <Grid item lg={5} xs={11}>
          {dataTable?.length > 1 && (
            <>
              <Typography>
                Forecast of {incident ? "Incident" : "Cumulative"} Deaths (
                {location.name === "US" ? "States" : location.name})
              </Typography>
              <FormGroup
                style={{
                  position: "absolute",
                  margin: "10px 10px",
                  zIndex: 100,
                  overflow: "visible",
                }}
              >
                <FormControlLabel
                  control={<Switch checked={stacked} onChange={handleStack} />}
                  label="Stacked"
                />
              </FormGroup>
              <CustomChart
                elementType="line"
                setState={setChartState}
                activeDatumIndex={activeDatumIndex}
                activeSeriesIndex={activeSeriesIndex}
                originalData={dataTable}
                quantile={quantile}
                stacked={stacked}
                location={location}
                asDate={asDate}
                targetDate={targetDate}
              />
            </>
          )}
        </Grid>
        {/* <Grid item xs={2}>
          {dataTable && dataTable.length > 1 && (
            <>
              <Autocomplete
                options={stackList}
                getOptionLabel={(option) => option.label}
                id="stack"
                disableClearable
                defaultValue={stackList[1]}
                onChange={(event, newValue) => {
                  // console.log(newValue);
                  setStacked(newValue.value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Stack" margin="normal" />
                )}
              />
              <Autocomplete
                options={LocationTable}
                getOptionLabel={(option) => option.name}
                id="states"
                disableClearable
                // defaultValue={LocationTable[0]}
                value={location}
                onChange={(event, newValue) => {
                  setLocation(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="States" margin="normal" />
                )}
              />
            </>
          )}
        </Grid> */}
        <Grid item xs={11}>
          {dataTable?.length > 1 && (
            <>
              <Typography>
                Ground Truth & Forecast of{" "}
                {incident ? "Incident" : "Cumulative"} Deaths (
                {location.name === "US" ? "US National" : location.name})
              </Typography>
              <ComparisonChart
                elementType="band"
                setState={setChartState}
                activeDatumIndex={activeDatumIndex}
                activeSeriesIndex={activeSeriesIndex}
                originalData={dataTable}
                upper={quantiles && quantiles[quantiles.length - 1]}
                lower={quantiles && quantiles[0]}
                center={
                  quantiles?.length > 0
                    ? quantiles[Math.floor(quantiles.length / 2)]
                    : null
                }
                gt={incident ? deathIncGT : deathCumGT}
                location={location}
                inc={incident}
                asDate={asDate}
                targetDate={targetDate}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default Dashboard;
