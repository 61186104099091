import React from 'react';
import { 
    Grid, 
    Container, 
    Typography,
    Paper,
    makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
      paddingTop: 10,
      paddingBottom: 10,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'justify',
      color: theme.palette.text.secondary,
    },
  }));
  
 export default () => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h4" gutterBottom align='center'>Disclaimer</Typography>
            <Typography variant="body1" gutterBottom paragraph>
              The COVID-19 Ensemble Dashboard includes data obtained 
              from model predictions provided by research teams 
              across the world. Please visit our “Models” page 
              (<Link to="/Models">link</Link>)
              for an overview of each model and the associated data licenses.
              We use this publicly available data to find the consensus 
              between the models, described in the “Ensemble Approach” page 
              (<Link to="/Ensemble">link</Link>).
              Our team makes the best effort to provide useful content, 
              but assumes no responsibility or liability for accuracy, 
              errors, or omissions in the data provided or linked 
              through this website.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};