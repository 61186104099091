import React from "react";
import { Chart } from "react-charts";
// import { IconButton } from "@material-ui/core";
// import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";

export default ({
  activeDatumIndex,
  activeSeriesIndex,
  targetDate,
  asDate,
  setState,
  originalData,
}) => {
  // const { data } = useChartConfig({
  //   series: 10,
  //   height: 100,
  //   // grouping: 'primary',
  //   // dataType: 'ordinal',
  //   // show: ['elementType']
  // })
  const data = React.useMemo(
    (data) => {
        const tmp = originalData
        .map((v, i) => {
          return {
            x: new Date(v.label),
            y: 0,
          };
        })
        .reduce((rv, x) => {
            (rv[x.y] = rv[x.y] || []).push(x);
            return rv;
          }, {});
        const tmp2 = Object.entries(tmp).map((v, i) => {
          return { label: 'forecast', data: v[1] };
        });
      return tmp2;
    },
    [originalData]
  );


  // Use data.lines[n].data[n].value as each datums secondary value
  // const getSecondary = React.useCallback((datum) => datum.value, []);
//   const [{ min, max }, setMinMaxState] = React.useState({
//     min: null,
//     max: null,
//   });

//   const series = React.useMemo(
//     () => ({
//       type: "line",
//     }),
//     []
//   );
  const axes = React.useMemo(
    () => [
      {
        primary: true,
        type: "utc",
        position: "bottom",
      },
      {
        type: "linear",
        position: "left",
        show: false,
      },
    ],
    []
    // [max, min]
  );
  const getSeriesStyle = React.useCallback(
    (series) => ({
      transition: "all .5s ease",
      opacity:
        activeSeriesIndex > -1
          ? series.index === activeSeriesIndex
            ? 0.5
            : 0.3
          : 0.3,
    }),
    [activeSeriesIndex]
  );
  const getDatumStyle = React.useCallback(
    (datum) => ({
      r:
        activeDatumIndex === datum.index &&
        activeSeriesIndex === datum.seriesIndex
          ? 7
          : activeDatumIndex === datum.index
          ? 5
          : datum.series.index === activeSeriesIndex
          ? 3
          : datum.otherHovered
          ? 2
          : 2,
    }),
    [activeDatumIndex, activeSeriesIndex]
  );
  const onFocus = React.useCallback(
    (focused) => {
      // console.log("focused", focused);
      setState({
        activeSeriesIndex2: focused ? focused.series.id : -1,
        activeDatumIndex2: focused ? focused.index : -1,
        targetDate2: targetDate,
      });
    },
    [setState, targetDate]
  );

  const handleClick = React.useCallback(
    (clicked) => {
      // console.log("click", clicked.primary)
      if (clicked && clicked.primary !== undefined)
        setState({
          targetDate2: clicked.primary,
        });
    },
    [setState]
  );

  const primaryCursor = React.useMemo(
    () => ({
      value: asDate,
    }),
    [asDate]
  );

//   const brush = React.useMemo(
//     () => ({
//       onSelect: (brushData) => {
//         if (brushData.start.getTime() !== brushData.end.getTime())
//           setMinMaxState({
//             min:
//               brushData.start < brushData.end ? brushData.start : brushData.end,
//             max:
//               brushData.start > brushData.end ? brushData.start : brushData.end,
//           });
//       },
//     }),
//     []
//   );

  return (
    <>
      {data?.length > 0 && (
        <>
          {/* <div style={{ textAlign: "right" }}>
            <IconButton
              style={{ position: "absolute", zIndex: 1 }}
              onClick={() =>
                setMinMaxState({
                  min: null,
                  max: null,
                })
              }
            >
              <ZoomOutMapIcon fontSize={'large'} />
            </IconButton>
          </div> */}
          <div style={{ height: 50 }}>
            <Chart
              data={data}
              // getSeries={getSeries}
              // getDatums={getDatums}
              // getPrimary={getPrimary}
              // getSecondary={getSecondary}
              // grouping={grouping}
            //   series={series}
              axes={axes}
            //   brush={brush}
              getSeriesStyle={getSeriesStyle}
              getDatumStyle={getDatumStyle}
              onFocus={onFocus}
              onClick={handleClick}
            //   tooltip
              primaryCursor={primaryCursor}
            />
          </div>
        </>
      )}
    </>
  );
};
