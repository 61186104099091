const us = require("./states-albers-10m.json")
const topojson = require("topojson-client");

interface Detail {
  id: string, name: string
}

var LocationToName: {[index: string]:any} = {};
var LocationTable: Detail[] = [{id:"US", name:"US"}];
topojson.feature(us, us.objects.states)
  .features.forEach((element: any) => {
    LocationToName[element.id]=element.properties.name;
    LocationTable.push({id: element.id, name: element.properties.name})
});


// console.log(LocationTable);
// console.log(locationToName);

export {
  LocationToName,
  LocationTable
}