import React, { useEffect, useRef, useState } from "react"

const Context = React.createContext(null)

export function Stage({ minx, miny, width, height, children }) {
  const svgRef = useRef(null)
  const [svg, setSvg] = useState(null)
  useEffect(() => setSvg(svgRef.current), [])
  if(minx === undefined)
    minx = 0;
  if(miny === undefined)
    miny = 0;
  return (
    <svg 
      ref={svgRef} 
      width={width} height={height} 
      viewBox={`${minx} ${miny} ${isNaN(width)?900:width} ${isNaN(height)?600:height}`}
      >
      <Context.Provider value={svg}>{children}</Context.Provider>
    </svg>
  )
}

export function useSvg() {
  return React.useContext(Context)
}