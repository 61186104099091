import React from 'react';
import './App.css';
import { 
  Router,
  Switch,
  Route
} from 'react-router-dom';
import history from "./utils/history";

import Header from './Header';
import Footer from './Footer';
import Dashboard from './Dashboard';
import Models from './Models';
import Ensemble from './Ensemble';
import News from './News';
import Team from './Team';
import NoMatch from './NoMatch';
import Disclaimer from './Disclaimer';
import Publications from './Publications';
import Contact from './Contact';

function App() {
  return (
    <Router history={history} >
      <div className="App">
        <Header />
        <Switch>
          <Route path="/" exact component={Dashboard} />
          <Route path="/Models" component={Models} />
          <Route path="/Ensemble" component={Ensemble} />
          <Route path="/News" component={News} />
          <Route path="/Team" component={Team} />
          <Route path="/Publications" component={Publications} />
          <Route path="/Contact" component={Contact} />
          <Route path="/Disclaimer" component={Disclaimer} />
          <Route component={NoMatch}/>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;